<template>
    <div class="flexPage darkPage">
        <div class="content">
            <!-- <div @click="$mts.goto('setting')" class="settings-box">
                <img class="settings-icon" src="@/assets/img/settings.png" alt="" />
            </div> -->
            <div class="myS">{{ $t('other.translate3') }}</div>
            <div class="user-boxes">
                <div class="avatar">
                    <img src="@/assets/img/avatar.png" alt="" />
                </div>
            </div>
            <p class="account">{{ userInfo.username.length > 20 ? address2simple(userInfo.username, 10) :
                userInfo.username }}</p>
            <p class="uid">UID：{{ userInfo.id }}</p>
        </div>

        <!-- <div class="ring">
            <img src="@/assets/img/ring.png" alt="">
            <van-notice-bar left-icon="" right-icon="arrow" :scrollable="false">
                <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
                    <van-swipe-item v-for="(item, index) of noticeVO" :key="index"
                        @click="$router.push({ name: 'notice', params: { id: item.id } })">{{ item.title
                        }}</van-swipe-item>
                </van-swipe>
            </van-notice-bar>
            <van-icon style="margin-top: 1.2723vw;" name="arrow" color="#616E85" />
        </div> -->

        <!-- 内容板块 -->
        <div class="cell-list">
            <div class="setting">{{ $t('other.translate2') }}</div>
            <!-- 我的资产 -->
            <van-cell @click="$mts.goto('assets')" is-link :border="true">
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/assets.png" alt="">
                        <span>{{ $t('assets.personal.my_assets') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#000000E5" />
                </template>
            </van-cell>
            <!-- 安全中心 -->
            <van-cell @click="$mts.goto('safe')" is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/safe.png" alt="">
                        <span>{{ $t('assets.personal.safe') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell>
            <!-- 实名认证 -->
            <van-cell @click="goReal" is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/real.png" alt="">
                        <span>{{ $t('assets.personal.kyc') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell>
            <!-- 交易所API绑定 -->
            <!-- <van-cell is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/exchange.png" alt="">
                        <span>{{ $t('assets.personal.exchange_binding') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell> -->
            <!-- 我的推广 -->
            <!-- <van-cell @click="$mts.goto('promotion')" is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/notice.png" alt="">
                        <span>{{ $t('assets.personal.my_promotion') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell> -->
            <!-- 设置 -->
            <van-cell @click="$mts.goto('setting')" is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/seting.png" alt="">
                        <span>{{ $t('other.translate1') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell>
            <!-- 退出登录 -->
            <van-cell @click="logoutVisible = true" is-link>
                <template #title>
                    <div class="cell-title">
                        <img class="cell-icon" src="@/assets/img/out.png" alt="">
                        <span>{{ $t('button.assets.logout') }}</span>
                    </div>
                </template>
                <template #right-icon>
                    <van-icon name="arrow" size="4.0712vw" color="#0D1421" />
                </template>
            </van-cell>
            <!--<van-cell @click="$mts.gourl('message')" title="消息中心" icon="coupon-o" is-link value=""  />
                    <van-cell @click="$mts.gourl('service')" title="联系客服" icon="service-o" is-link value=""  /> -->
        </div>
        <van-dialog v-model="logoutVisible" :title="$t('button.assets.logout')" :show-cancel-button="false"
            :showConfirmButton="false">
            <div class="contentBox">{{ $t('validate.assets.t1') }}</div>
            <div class="btnBox">
                <div class="btnC" @click="logoutVisible = false">{{ $t('button.otc.cancel') }}</div>
                <div style="width: 1px;height: 20px;background: #0000000D"></div>
                <div class="btnS" @click="onConfirm">{{ $t('button.otc.sure') }}</div>
            </div>
        </van-dialog>

        <downbar />
    </div>
</template>
<script>
import downbar from "@/views/modules/downbar.vue";
// import { address2simple } from "@/utils/address";
export default {
    data() {
        return {
            logoutVisible: false, // 退出登录模态框
            userInfo: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : '',
            noticeVO: []
        }
    },
    components: { downbar },
    mounted() {
        this.getNotice();
    },
    methods: {
        // 确认退出登录
        onConfirm() {
            this.logoutVisible = false;
            this.$store.commit('setLoginStatus', false);
            this.$mts.signout();
        },
        address2simple(address, cut) {
            let length = address.length;
            if (!address || length < cut * 2) {
                return "";
            }
            let prefix = address.substring(0, cut);
            let suffix = address.substring(length - cut, length);
            return `${prefix}...${suffix}`;
        },
        goReal() {
            this.$http.post(this.host + '/uc/approve/security/setting').then((response) => {
                var resp = response.body;
                if (resp.data.realAuditing == 1) {
                    this.$toast(this.$t('other.translate158'));
                } else if (resp.data.realVerified == 1) {
                    this.$toast(this.$t('other.translate159'));
                } else {
                    this.$mts.goto('real')
                }
            });

        }
    },
}
</script>

<style scoped>
.btnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;
}

.btnC {
    height: 52px;
    width: 140px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 52px;
    text-align: center;
    color: #00000080
}

.btnS {
    height: 52px;
    width: 140px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 52px;
    text-align: center;
    color: #0052FF
}

.contentBox {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 24px
}

::v-deep .van-dialog {
    width: 288px;
    height: 205px;
    border-radius: 16px;
}

::v-deep .van-dialog__footer {
    border: none !important;
    margin-top: 69px
}

::v-deep .van-dialog .van-dialog__footer .van-button {
    border: none !important;
}

::v-deep .van-dialog__header {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
}

.myS {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}

.setting {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin-left: 16px;
    margin-bottom: 16px;
}

::v-deep .van-cell {
    border-bottom: 1px solid #F6F6F6 !important;
}

.content {
    padding: 0 5.0891vw;
    box-sizing: border-box;
}

.settings-box {
    padding-top: 4.0712vw;
    box-sizing: border-box;
    display: flex;
    justify-content: end;
    align-items: end;
}

.settings-box .settings-icon {
    width: 6.1069vw;
    height: 6.1069vw;
}

.user-boxes {
    margin-top: 7.6336vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-boxes .avatar {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-boxes .avatar img {
    width: 64px;
    height: 64px;
}

.user-boxes .user-info {
    margin-left: 4.0712vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.account {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #000000;
    margin: 8px 0 5px 0;

}

.uid {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #00000080
}

.user-boxes .user-info .identity-boxes {
    display: flex;
    align-items: center;
    width: 21.374vw;
    padding: 1.0178vw 3.0534vw;
    box-sizing: border-box;
    height: 6.1069vw;
    border-radius: 3.0534vw;
    background: rgba(56, 97, 251, 0.1);
}

.user-boxes .user-info .identity-boxes img {
    width: 4.0712vw;
    height: 4.0712vw;
    margin-right: 1.0178vw;
}

.user-boxes .user-info .identity-boxes span {
    font-size: 3.5623vw;
    font-weight: 590;
    color: #3861FB;
}

.ring {
    padding: 0 5.0891vw;
    margin-top: 3.0534vw;
    display: flex;
    align-items: center;
    height: 11.1959vw;
    box-sizing: border-box;
}

.ring .van-notice-bar {
    flex: 1;
    background-color: transparent;
}

.ring .notice-swipe {
    height: 40px;
}

.notice-swipe .van-swipe__track .van-swipe-item {
    width: 100% !important;
    color: #616E85;
    font-size: 3.0534vw;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ring .van-notice-bar .van-notice-bar__wrap {
    height: 11.1959vw;
    display: flex;
    align-items: center;
}

.ring img {
    width: 5.0891vw;
    height: 5.0891vw;
}

.cell-list {
    border-top: 2.0356vw solid #F8FAFD;
    padding-top: 5.0891vw;
    background: #FFFFFF;
}

.cell-list .cell-title {
    display: flex;
    align-items: center;
}

.cell-list .cell-title .cell-icon {
    margin-right: 3.0534vw;
    width: 32px;
    height: 32px;
}

.cell-list .cell-title span {
    color: #000;
    font-size: 4.0712vw;
    font-weight: 500;
    font-family: Inter;
}

.cell-list .van-cell {
    padding: 4.0712vw 5.0891vw;
    background: #FFFFFF;
    border: unset;
    display: flex;
    align-items: center;
}

.cell-list .van-cell::after {
    border: unset;
}
</style>
